import React from "react";

export default class ControllerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }

  render() {
    return (
      <div>

        <div>Controller</div>
        <div
          id="divControllerCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
          <br />var {this.props.ComponentName}Service = require('../Services/{this.props.ComponentName}Services')
          <br />
          <br />exports.get{this.props.ComponentName}s = async function (req, res, next) {'{'}
          <br />
          <br />    var page = req.params.page ? req.params.page : 1;
          <br />    var limit = req.params.limit ? req.params.limit : 10;
          <br />    try {'{'}
          <br />        var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}s = await {this.props.ComponentName}Service.get{this.props.ComponentName}s({'{'}{'}'}, page, limit)
          <br />       return res.status(200).json({'{'} status: 200, data: {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}s, message: "Succesfully Retrieved {this.props.ComponentName}s" {'}'});
          <br />    {'}'} catch (e) {'{'}
          <br />        return res.status(400).json({'{'} status: 400, message: e.message {'}'});
          <br />    {'}'}
          <br />next();
          <br />{'}'}
          <br />
          <br />exports.get{this.props.ComponentName} = async function (req, res, next) {'{'}
          <br />
          <br />    var {this.props.ComponentName}Id = req.params.{this.props.ComponentName.toLowerCase()}id;
          <br />    try {'{'}
          <br />        var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)} = await {this.props.ComponentName}Service.get{this.props.ComponentName}({'{'}_id:{this.props.ComponentName}Id{'}'})
          <br />        return res.status(200).json({'{'} status: 200, data: {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}, message: "Succesfully Retrieved {this.props.ComponentName}" {'}'});
          <br />    {'}'} catch (e) {'{'}
          <br />        return res.status(400).json({'{'} status: 400, message: e.message {'}'});
          <br />    {'}'}
          <br />next();
          <br />{'}'}
          <br />
          <br />exports.add{this.props.ComponentName} = async function (req, res, next) {'{'}
          <br />    console.log(req.headers);
          <br />    var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)} = req.headers.{this.props.ComponentName.toLowerCase()};
          <br />    var active = req.headers.active;
          <br />
          <br />    try {'{'}
          <br />        var result = await {this.props.ComponentName}Service.add{this.props.ComponentName}({'{'} {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}, active {'}'});
          <br />        return res.status(200).json({'{'} status: 200, data: result, message: "Succesfully Added {this.props.ComponentName}" {'}'});
          <br />    {'}'} catch (e) {'{'}
          <br />        return res.status(400).json({'{'} status: 400, message: e.message {'}'});
          <br />    {'}'}
          <br />next();
          <br />{'}'}
          <br />

          <br />
        </div>
      </div>
    );
  }
}
