import React from "react";

export default class ConfigurationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({ ComponentName: e.target.value });
    this.props.setComponentName(e.target.value);
  };

  render() {
    return (<>
      <div style={{ border: "1px solid", padding: "15px" }}>
        Component Name &nbsp;
        <input
          type="text"
          value={this.state.ComponentName}
          onChange={this.handleInputChange}
        />
        &nbsp;&nbsp;

        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              document.getElementById("divParentCode").innerText
            );
          }}
          value="Copy Parent"
          style={{ width: "120px" }}
        />
        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              this.props.app === "express" ? document.getElementById("divControllerCode").innerText : document.getElementById("divChildCode").innerText
            );
          }}
          value={this.props.app === "express" ? "Copy Controller" : "Copy Child"}
          style={{ width: "120px" }}
        />
        {this.props.app === "express" ? <>
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divModelCode").innerText
              );
            }}
            value="Copy Model"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divRouteCode").innerText
              );
            }}
            value="Copy Route"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divServiceCode").innerText
              );
            }}
            value="Copy Service"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={async (e) => {
              var dir = await window.showDirectoryPicker();
              var subDir = await dir.getDirectoryHandle("APIs", { create: true })
              var index = await subDir.getFileHandle("index.js", { create: true })
              const size = (await index.getFile()).size;
              index.createWritable({ keepExistingData: true })
                .then(writer => { return Promise.all([writer.seek(size), writer]) })
                .then(([, writer]) => {
                  return Promise.all([writer.write(new Blob([size === 0 ? document.getElementById("divParentCode").innerText : document.getElementById("divParentCode").innerText.substring(99)])), writer])
                })
                .then(([, writer]) => { return writer.close(); });
              var models = await subDir.getDirectoryHandle("Models", { create: true })
              var controllers = await subDir.getDirectoryHandle("Controllers", { create: true })
              var routes = await subDir.getDirectoryHandle("Routes", { create: true })
              var services = await subDir.getDirectoryHandle("Services", { create: true })
              var Names = this.state.ComponentName
              Names.split(',').map(async comp => {
                this.setState({ ComponentName: comp });
                this.props.setComponentName(comp);
                var model = await models.getFileHandle(comp.substring(0, 1).toLocaleUpperCase() + comp.substring(1) + ".js", { create: true })
                var modelFile = await model.createWritable()
                await modelFile.write(document.getElementById("divModelCode").innerText)
                await modelFile.close()
                var route = await routes.getFileHandle(comp.substring(0, 1).toLocaleUpperCase() + comp.substring(1) + "Routes.js", { create: true })
                var routesFile = await route.createWritable()
                await routesFile.write(document.getElementById("divRouteCode").innerText)
                await routesFile.close()
                var controller = await controllers.getFileHandle(comp.substring(0, 1).toLocaleUpperCase() + comp.substring(1) + "Controller.js", { create: true })
                var controllerFile = await controller.createWritable()
                await controllerFile.write(document.getElementById("divControllerCode").innerText)
                await controllerFile.close()
                var service = await services.getFileHandle(comp.substring(0, 1).toLocaleUpperCase() + comp.substring(1) + "Services.js", { create: true })
                var serviceFile = await service.createWritable()
                await serviceFile.write(document.getElementById("divServiceCode").innerText)
                await serviceFile.close()
              })
              this.setState({ ComponentName: Names });
              this.props.setComponentName(Names);


            }}
            value="Generate Project"
            style={{ width: "120px" }}
          /></> : <></>}
      </div>

    </>);
  }
}
