import React from "react";
import FieldsOutput from "./FieldsOutput";

export default class ReactApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }
  render() {
    return (

      <FieldsOutput
        ComponentName={this.props.ComponentName}
        Fields={this.props.Fields}
      />
    );
  }
}
