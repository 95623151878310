import React from "react";
import ClassOutput from "./ClassOutput";

export default class ReactApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }
  render() {
    return (

      <ClassOutput
        ComponentName={this.props.ComponentName}
        Navigates={this.props.Navigates}
      />
    );
  }
}
