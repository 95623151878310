import React from "react";
import ReactClassConfig from "./ReactClassConfig";
import ReactDropdownConfig from "./ReactDropdownConfig";
import ReactFieldsConfig from "./ReactFieldsConfig";
import ExpressConfig from "./ExpressConfig";
import ReactClass from './ReactClass';
import ReactDropdown from './ReactDropdown';
import ReactFields from './ReactFields';
import ExpressApp from "./ExpressApp";

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      app: 'reactClass',
      ComponentName: "",
      EntityName: "",
      TriggerName: "",
      EntityURL: "",
      Value: "",
      Label: "",
      Navigates: false,
      Fields: [{ name: "", type: "string", default: "\"\"", control: "text" }]
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ ComponentName: nextProps.ComponentName })
  }
  handleApp = (ctrl, e) => {
    var nameWords = this.state.ComponentName.split(' ');
    var compName = "";
    for (let i = 0; i < nameWords.length; i++) { compName += nameWords[i].substring(0, 1).toUpperCase() + nameWords[i].substring(1) }
    switch (this.state.app) {
      case 'reactClass':
        return <ReactClass ComponentName={compName} Navigates={this.state.Navigates} />
          ;
      case 'reactDropdown':
        var entityWords = this.state.EntityName.split(' ');
        var entityName = ""
        for (let i = 0; i < entityWords.length; i++) { entityName += entityWords[i].substring(0, 1).toUpperCase() + entityWords[i].substring(1) }

        var triggerWords = this.state.TriggerName.split(' ');
        var triggerName = ""
        for (let i = 0; i < triggerWords.length; i++) { triggerName += triggerWords[i].substring(0, 1).toUpperCase() + triggerWords[i].substring(1) }
        return <ReactDropdown ComponentName={compName} DropDown={{ Name: entityName, Trigger: triggerName, URL: this.state.EntityURL.replace(' ', ''), Value: this.state.Value.replace(' ', ''), Label: this.state.Label.replace(' ', '') }} />
          ;
      case 'reactFields':
        return <ReactFields ComponentName={compName} Fields={this.state.Fields} />
          ;
      default:
        return <ExpressApp ComponentName={compName} />
          ;
    }
  }
  handleConfig = (ctrl, e) => {
    switch (this.state.app) {
      case 'reactClass':
        return <ReactClassConfig
          setComponentName={(ComponentName) =>
            this.setState({ ComponentName })}
          navigates={(value) => this.setState({ Navigates: value })}
          app={this.state.app}
        />;
      case 'reactDropdown':
        return <ReactDropdownConfig
          setComponentName={(ComponentName) =>
            this.setState({ ComponentName })}
          setEntityName={(EntityName) =>
            this.setState({ EntityName })}
          setTriggerName={(TriggerName) =>
            this.setState({ TriggerName })}
          setEntityURL={(EntityURL) =>
            this.setState({ EntityURL })}
          setValue={(Value) =>
            this.setState({ Value })}
          setLabel={(Label) =>
            this.setState({ Label })}
          navigates={(value) => this.setState({ Navigates: value })}
          app={this.state.app}
        />;
      case 'reactFields':
        return <ReactFieldsConfig
          setComponentName={(ComponentName) =>
            this.setState({ ComponentName })}
          setFields={(Fields) =>
            this.setState({ Fields })}
          app={this.state.app}
        />;
      default:
        return <ExpressConfig
          setComponentName={(ComponentName) =>
            this.setState({ ComponentName })}
          setEntityName={(EntityName) =>
            this.setState({ EntityName })}
          setTriggerName={(TriggerName) =>
            this.setState({ TriggerName })}
          setEntityURL={(EntityURL) =>
            this.setState({ EntityURL })}
          setValue={(Value) =>
            this.setState({ Value })}
          setLabel={(Label) =>
            this.setState({ Label })}
          navigates={(value) => this.setState({ Navigates: value })}
          app={this.state.app}
        />
          ;
    }
  }

  render() {
    return (
      <div style={{ "font-size": "14px" }}>
        <br />
        <button onClick={(e) => this.setState({ app: 'reactClass' })}>ReactJS Class</button>
        <button onClick={(e) => this.setState({ app: 'reactDropdown' })}>ReactJS Dropdown</button>
        <button onClick={(e) => this.setState({ app: 'reactFields' })}>ReactJS Fields</button>
        <button onClick={(e) => this.setState({ app: 'express' })}>ExpressJS</button>
        <br />
        <br />

        {
          this.handleConfig()
        }

        <br />
        {
          this.handleApp()
        }

      </div>
    );
  }
}
