import React from "react";

export default class ConfigurationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      EntityName: "",
      TriggerName: "",
      EntityURL: "",
      Value: "",
      Label: "",
      Navigates: false,
      Fields: [{ name: "", type: "string", default: "\"\"", control: "text" }]
    };
  }

  handleInputChange = (e) => {
    this.setState({ ComponentName: e.target.value });
    this.props.setComponentName(e.target.value);
  };

  handleFieldsChange = (idx, field, e) => {
    var arr = this.state.Fields;
    switch (field) {
      case 'name':
        arr[idx].name = e.target.value;
        this.setState(arr);
        this.props.setFields(arr);
        return;
      case 'type':
        arr[idx].type = e.target.value;
        arr[idx].default = e.target.value === 'string' ? "\"\"" : e.target.value === 'number' ? 0 : e.target.value === 'bool' ? false : e.target.value === 'object' ? "{}" : e.target.value === 'array' ? "[]" : e.target.value === 'date' ? "() => new Date().toISOString().substring(0, 10)" : "";
        arr[idx].control = e.target.value === 'date' ? "calendar" : e.target.value === 'bool' ? "check" : e.target.value === 'array' ? "select" : "text";
        this.setState(arr);
        this.props.setFields(arr);
        return;
      case 'default':
        arr[idx].default = e.target.value;
        this.setState(arr);
        this.props.setFields(arr);
        return;
      case 'control':
        arr[idx].control = e.target.value;
        this.setState(arr);
        this.props.setFields(arr);
        return;
      default:
        return;
    }
  };

  handleEntityChange = (e) => {
    this.setState({ EntityName: e.target.value });
    this.props.setEntityName(e.target.value);
  };
  handleTriggerChange = (e) => {
    this.setState({ TriggerName: e.target.value });
    this.props.setTriggerName(e.target.value);
  };
  handleURLChange = (e) => {
    this.setState({ EntityURL: e.target.value });
    this.props.setEntityURL(e.target.value);
  };
  handleValueChange = (e) => {
    this.setState({ Value: e.target.value });
    this.props.setValue(e.target.value);
  };

  render() {
    return (<>
      <div style={{ border: "1px solid", padding: "15px" }}>
        Component Name &nbsp;
        <input
          type="text"
          value={this.state.ComponentName}
          onChange={this.handleInputChange}
        />
        &nbsp;&nbsp;

        <input
          type="button"
          onClick={(e) => {
            this.setState({ Fields: [...this.state.Fields, { name: "", type: "string", default: "\"\"", control: "text" }] })
          }}
          value="Add Field"
          style={{ width: "120px" }}
        />
        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              this.props.app === "express" ? document.getElementById("divControllerCode").innerText : document.getElementById("divChildCode").innerText
            );
          }}
          value="Copy"
          style={{ width: "120px" }}
        />
        {this.props.app === "express" ? <>
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divModelCode").innerText
              );
            }}
            value="Copy Model"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divRouteCode").innerText
              );
            }}
            value="Copy Route"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divServiceCode").innerText
              );
            }}
            value="Copy Service"
            style={{ width: "120px" }}
          /></> : <></>}
      </div>

      {this.state.Fields.map((data, idx) => <div style={{ border: "1px solid", padding: "15px" }}>
        Field Name &nbsp;
        <input
          type="text"
          value={data.name}
          onChange={(e) => this.handleFieldsChange(idx, 'name', e)}
        />
        &nbsp;&nbsp;
        Type &nbsp;
        <select name="s" id="s"
          value={data.type}
          onChange={(e) => {
            this.handleFieldsChange(idx, 'type', e)
          }}
        >
          <option selected value="string">String</option>
          <option value="number">Number</option>
          <option value="bool">Boolean</option>
          <option value="date">Date</option>
          <option value="object">Object</option>
          <option value="array">Array</option>

        </select>
        &nbsp;&nbsp;
        Default Value &nbsp;
        <input
          type="text"
          value={data.default}
          onChange={(e) => this.handleFieldsChange(idx, 'default', e)}
        />
        &nbsp;&nbsp;
        Control &nbsp;
        <select name="s" id="s"
          value={data.control}
          onChange={(e) => {
            this.handleFieldsChange(idx, 'control', e)
          }}
        >
          <option selected value="text">Textbox</option>
          <option value="check">Checkbox</option>
          <option value="radio">Radio</option>
          <option value="calendar">Calendar</option>
          <option value="select">Dropdown</option>

        </select>

        <input
          type="button"
          onClick={(e) => {
            this.setState({ Fields: this.state.Fields.filter(row => row.name !== data.name) })
            this.props.setFields(this.state.Fields.filter(row => row.name !== data.name))
          }}
          value="Remove"
          style={{ width: "120px" }}
        />
      </div>
      )}
    </>);
  }
}
