import React from "react";

export default class RouteSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }

  render() {
    return (
      <div>
        <div>Route</div>

        <div
          id="divRouteCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
          var express = require('express');
                       <br />
                var router = express.Router();
                <br /><br />
                var {this.props.ComponentName}Controller = require('../Controllers/{this.props.ComponentName}Controller');
                <br />
          <br />
router.get('/', {this.props.ComponentName}Controller.get{this.props.ComponentName}s);<br />
router.get('/:{this.props.ComponentName.toLowerCase()}id', {this.props.ComponentName}Controller.get{this.props.ComponentName}); <br />
router.post('/', {this.props.ComponentName}Controller.add{this.props.ComponentName});
                <br /><br />
    module.exports = router;
                <br />
        </div>
      </div>
    );
  }
}
