import React from "react";
import ControllerSection from "./ControllerSection";
import ModelSection from "./ModelSection";
import RouteSection from "./RouteSection";
import ServiceSection from "./ServiceSection";

export default class ExpressApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }
  render() {
    return (
      <div style={{ "font-size": "14px" }}>
        <div
          id="divParentCode"
          style={{ background: "yellow", border: "1px solid", padding: "15px" }}
        >
          var express = require("express");<br />
          var cors = require("cors");<br />
          var app = express();<br />
          app.use(cors());<br /><br />
          var {this.props.ComponentName} = require("./Routes/{this.props.ComponentName}Routes");<br />
          app.use('/{this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}s',{this.props.ComponentName});

        </div>
        <br />
        <ControllerSection
          ComponentName={this.props.ComponentName}
          Navigates={this.props.Navigates}
        />
        <ModelSection
          ComponentName={this.props.ComponentName}
          Navigates={this.props.Navigates}
        />
        <RouteSection
          ComponentName={this.props.ComponentName}
          Navigates={this.props.Navigates}
        />
        <ServiceSection
          ComponentName={this.props.ComponentName}
          Navigates={this.props.Navigates}
        />
      </div>
    );
  }
}
