import React from "react";

export default class ModelSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }

  render() {
    return (
      <div>
      <div>Model</div>
        <div
          id="divModelCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
                var mongoose = require('mongoose');
                       <br/>
                <br />
                const {this.props.ComponentName}Schema = new mongoose.Schema({'{'}
                
                       <br/>
                <br />
                       <br/>
                <br />{'}'},
  {'{'}<br/>
    collection: "{this.props.ComponentName}",<br/>
    {'}'});<br/>
                       <br/>
const {this.props.ComponentName} = mongoose.model('{this.props.ComponentName}', {this.props.ComponentName}Schema);
                <br />
                       <br/>
module.exports = {this.props.ComponentName};
                <br />
        </div>
      </div>
    );
  }
}
