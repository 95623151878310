import React from "react";

export default class ConfigurationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }

  handleInputChange = (e) => {
    this.setState({ ComponentName: e.target.value });
    this.props.setComponentName(e.target.value);
  };

  render() {
    return (<>
      <div style={{ border: "1px solid", padding: "15px" }}>
        Component Name &nbsp;
        <input
          type="text"
          value={this.state.ComponentName}
          onChange={this.handleInputChange}
        />
        &nbsp;&nbsp;
        <input
          type="checkbox"
          value={this.props.Navigates}
          onClick={(e) => {
            this.props.navigates(!this.state.Navigates);
            this.setState({ Navigates: !this.state.Navigates });
          }}
        />
        Navigates&nbsp;&nbsp;&nbsp;&nbsp;
        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              document.getElementById("divParentCode").innerText
            );
          }}
          value="Copy Parent"
          style={{ width: "120px" }}
        />
        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              this.props.app === "express" ? document.getElementById("divControllerCode").innerText : document.getElementById("divChildCode").innerText
            );
          }}
          value={this.props.app === "express" ? "Copy Controller" : "Copy Child"}
          style={{ width: "120px" }}
        />
        {this.props.app === "express" ? <>
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divModelCode").innerText
              );
            }}
            value="Copy Model"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divRouteCode").innerText
              );
            }}
            value="Copy Route"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divServiceCode").innerText
              );
            }}
            value="Copy Service"
            style={{ width: "120px" }}
          /></> : <></>}
      </div>
    </>);
  }
}
