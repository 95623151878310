import React from "react";

export default class ServiceSection extends React.Component {
        constructor(props) {
                super(props);
                this.state = {
                        ComponentName: "",
                        Navigates: false,
                };
        }

        render() {
                return (
                        <div>
                                <div>Service</div>

                                <div
                                        id="divServiceCode"
                                        style={{ background: "silver", border: "1px solid", padding: "15px" }}
                                >
                                        var {this.props.ComponentName} = require('../Models/{this.props.ComponentName}')
                                        <br />
                                        <br />
                                        exports.get{this.props.ComponentName}s = async function (query, page, limit) {'{'}
                                        <br />
                                        <br />try {'{'}
                                        <br />
                                        var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}s = await {this.props.ComponentName}.find(query)
                                        <br />
                                        return {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}s;
                                        <br />
                                        {'}'} catch (e)  {'{'}
                                        <br />
                                        throw Error('Error while fetching {this.props.ComponentName}s')
                                        <br /> {'}'}
                                        <br /> {'}'}
                                        <br />
                                        <br />
                                        exports.get{this.props.ComponentName} = async function (query) {'{'}
                                        <br />
                                        <br />try {'{'}
                                        <br />var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)} = await {this.props.ComponentName}.find(query)
                                        <br />
                                        return {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)};
                                        <br />
                                        {'}'} catch (e)  {'{'}
                                        <br />
                                        throw Error('Error while fetching {this.props.ComponentName}')
                                        <br /> {'}'}
                                        <br /> {'}'}
                                        <br />
                                        <br />exports.add{this.props.ComponentName} = async function (record) {'{'}
                                        <br />
                                        <br />try {'{'}
                                        <br />
                                        <br />
                                        var {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)} = new {this.props.ComponentName}(record);
                                        <br />
                                        {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}.save()
                                        <br />
                                        return "Success";
                                        <br />
                                        {'}'} catch (e)  {'{'}
                                        <br />
                                        throw Error('Error while Adding {this.props.ComponentName.substring(0, 1).toLowerCase() + this.props.ComponentName.substring(1)}')
                                        <br /> {'}'}
                                        <br /> {'}'}
                                        <br />
                                </div>
                        </div>
                );
        }
}
