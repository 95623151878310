import React from "react";
import DropdownOutput from "./DropdownOutput";

export default class ReactApp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }
  render() {
    return (

      <DropdownOutput
        ComponentName={this.props.ComponentName}
        DropDown={this.props.DropDown}
      />
    );
  }
}
