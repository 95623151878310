import React from "react";

export default class FieldsOutput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
      Fields: []
    };
  }
  componentWillReceiveProps(props) {
    // You don't have to do this check first, but it can help prevent an unneeded render
    this.setState({ Fields: props.Fields });
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <div
          id="divChildCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
          import React {", { useState, useEffect }"} from 'react';
          <br />
import GridItem from 'components/Grid/GridItem.js'; <br />
import GridContainer from 'components/Grid/GridContainer.js'; <br />
import CustomInput from 'components/CustomInput/CustomInput.js'; <br /> <br />

export default function {this.props.ComponentName}(props) {"{"}
          <br /><br />
          {
            this.props.Fields.map((data, idx) =>
              <div>const [{data.name.substring(0, 1).toLowerCase() + data.name.substring(1)}, set{data.name}] = useState({data.default});</div>)}

          <br />  <br />
           const handleChange = (ctrl, e) ={"> {"}<br />

    switch (ctrl) {"{"}<br />
          {this.props.Fields.map((data, idx) =>
            <div>&nbsp;&nbsp;&nbsp;&nbsp;case '{data.name.substring(0, 1).toLowerCase() + data.name.substring(1)}':<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;set{data.name}({(data.type === 'string' || data.type === 'number') ? "e.currentTarget.value" : "e.target.checked"});<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return;<br /></div>)}
      &nbsp;&nbsp;&nbsp;&nbsp;default:<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return;<br />
          {"}"}<br />
          {"}"}<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (
        <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {"<div>"}
          <br />
          {this.props.Fields.map((data, idx) => data.control === "text" ? <>
            {"<GridContainer><GridItem xs={12} sm={12} md={12} style={{ alignSelf: 'flex-end' }} ><CustomInput labelText='" + data.name + "' id='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "' formControlProps={{ fullWidth: true }}" +
              " inputProps={{ disabled: false, defaultValue: " + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + ", onChange: (e) => handleChange('" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', e) }} /></GridItem></GridContainer> "}<br /></>
            : data.control === "check" ? <>{"<GridContainer><GridItem xs={12} sm={12} md={12} style={{ alignSelf: 'flex-end' }} ><GreenCheckbox id='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "' defaultChecked={" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "} onChange={(e) => handleChange('"
              + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', e)} inputProps={{disabled: false}} /> " + data.name + "</GridItem></GridContainer> "}<br /></>
              : data.control === "calendar" ? <>{"<GridContainer><GridItem xs={12} sm={12} md={12} style={{ alignSelf: 'flex-end' }} ><form noValidate><TextField id='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "' label='" + data.name + "' type='date' defaultValue={" + data.name.substring(0, 1).toLowerCase()
                + data.name.substring(1) + "} InputLabelProps={{shrink: true}} inputProps={{disabled: false, onChange: (e) => handleChange('" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', e)}} /></form></GridItem></GridContainer> "}<br /></> :
                data.control === "radio" ? <>{"<GridContainer><GridItem xs={12} sm={12} md={6} style={{ alignSelf: 'flex-end' }} ><GreenRadio id='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "' checked={" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "} onChange={(e) => handleChange('"
                  + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', e)} value='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "' name='rb" + data.name + "' inputProps={{ 'aria-label': '" + data.name + "' }} />" + data.name + "</GridItem></GridContainer>"}<br /></>
                  : data.control === "select" ? <>{"<GridContainer><GridItem xs={12} sm={12} md={12} style={{ alignSelf: 'flex-end' }} ><FormControl className={classes.formControl} fullWidth={true}>  <InputLabel htmlFor='" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "'>" + data.name + "</InputLabel><Select native defaultValue={" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + ".value} inputProps={{name: '" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', id: '" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "'}} onChange={e => handleChange('" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "', e) } label={" + data.name + "s}>{" + data.name.substring(0, 1).toLowerCase() + data.name.substring(1) + "s.map((item, idx) => <option value={item.value}>{item.label}</option>)}</Select></FormControl></GridItem></GridContainer>"}<br /></> : <>{""}<br /></>
          )}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        {"</div> "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ) <br />{"}"}
        </div>
      </div >
    );
  }
}
