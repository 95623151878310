import React from "react";

export default class DropdownOutput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }

  render() {
    return (
      <div>
        <br />
        <br />
        <div
          id="divChildCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
          import React {", { useState, useEffect }"} from 'react';
          <br />
export default function {this.props.ComponentName}(props) {"{"}
          <br />
          {this.props.DropDown.Name.length > 0 ? <>
            const [loading, setLoading] = useState(false);<br />
            const [{this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1)}s, set{this.props.DropDown.Name}s] = useState([]);<br />
const [{this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1)}, set{this.props.DropDown.Name}] = useState({"{"}value&#58; 	&ldquo; &rdquo;,label&#58;  	&ldquo; &rdquo;{"}"});
          <br />
            <br />

            {"useEffect(() => {"}<br />
            {"let unmounted = false;"}<br />
            {"async function getItems() {"}<br />
            {"const response = await fetch(\"" + this.props.DropDown.URL + "\");"}<br />
            {"const body = await response.json();"}<br />
            {"if (!unmounted) {"}<br />
            {"if (!props.ticket) {"}<br />
            {"body.data[0] ? set" + this.props.DropDown.Name + "({label: body.data[0]." + this.props.DropDown.Label + ", value: body.data[0]." + this.props.DropDown.Value + ",}): set" + this.props.DropDown.Name + "({});}"}<br />
            {"set" + this.props.DropDown.Name + "s(body.data.map(({" + this.props.DropDown.Value + ", " + this.props.DropDown.Label + "}) => ({label: " + this.props.DropDown.Label + ",value: " + this.props.DropDown.Value + ",})));"}<br />
            {"setLoading(false);"}<br />
            {"}"}<br />
            {"}"}<br />
            {"getItems();"}<br />
            {"return () => {"}<br />
            {"unmounted = true;"}<br />
            {"};"}<br />
            {"}, [" + this.props.DropDown.Trigger.substring(0, 1).toLowerCase() + this.props.DropDown.Trigger.substring(1) + "]);"}<br />
            <br />
            <br /></> : <></>}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"<div>"}
          {this.props.Navigates ? <br /> : ""}
          {this.props.Navigates ? <br /> : ""}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br />
          {"<select disabled={loading} name=\"" + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + "s\" id=\"" + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + "s\""}<br />
          {"value={" + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + ".value}"}<br />
          {"onChange={(e) => {"}<br />
          {"set" + this.props.DropDown.Name + "({ value: e.target.value, label: " + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + "s.filter((" + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + ") => " + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + ".value === e.target.value)[0].label });"}<br />
          {"}}"}<br />
          {">"}<br />
          {"{" + this.props.DropDown.Name.substring(0, 1).toLowerCase() + this.props.DropDown.Name.substring(1) + "s.map(({ label, value }) => ("}<br />
          {"<option key={value} value={value}>{label}</option>"}<br />
          {"))}"}<br />
          {"</select>"}<br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"</div> "}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; ) <br />{"}"}
        </div>
      </div>
    );
  }
}
