import React from "react";

export default class ConfigurationSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      EntityName: "",
      TriggerName: "",
      EntityURL: "",
      Value: "",
      Label: "",
    };
  }

  handleInputChange = (e) => {
    this.setState({ ComponentName: e.target.value });
    this.props.setComponentName(e.target.value);
  };
  handleEntityChange = (e) => {
    this.setState({ EntityName: e.target.value });
    this.props.setEntityName(e.target.value);
  };
  handleTriggerChange = (e) => {
    this.setState({ TriggerName: e.target.value });
    this.props.setTriggerName(e.target.value);
  };
  handleURLChange = (e) => {
    this.setState({ EntityURL: e.target.value });
    this.props.setEntityURL(e.target.value);
  };
  handleValueChange = (e) => {
    this.setState({ Value: e.target.value });
    this.props.setValue(e.target.value);
  };
  handleLabelChange = (e) => {
    this.setState({ Label: e.target.value });
    this.props.setLabel(e.target.value);
  };

  render() {
    return (<>
      <div style={{ border: "1px solid", padding: "15px" }}>
        Component Name &nbsp;
        <input
          type="text"
          value={this.state.ComponentName}
          onChange={this.handleInputChange}
        />
        &nbsp;&nbsp;

        <input
          type="button"
          onClick={(e) => {
            navigator.clipboard.writeText(
              this.props.app === "express" ? document.getElementById("divControllerCode").innerText : document.getElementById("divChildCode").innerText
            );
          }}
          value="Copy"
          style={{ width: "120px" }}
        />
        {this.props.app === "express" ? <>
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divModelCode").innerText
              );
            }}
            value="Copy Model"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divRouteCode").innerText
              );
            }}
            value="Copy Route"
            style={{ width: "120px" }}
          />
          <input
            type="button"
            onClick={(e) => {
              navigator.clipboard.writeText(
                document.getElementById("divServiceCode").innerText
              );
            }}
            value="Copy Service"
            style={{ width: "120px" }}
          /></> : <></>}
      </div>

      <div style={{ border: "1px solid", padding: "15px" }}>
        Entity &nbsp;
        <input
          type="text"
          value={this.state.EntityName}
          onChange={this.handleEntityChange}
        />
        &nbsp;&nbsp;
        Trigger &nbsp;
        <input
          type="text"
          value={this.state.TriggerName}
          onChange={this.handleTriggerChange}
        />
        &nbsp;&nbsp;
        URL &nbsp;
        <input
          type="text"
          value={this.state.EntityURL}
          onChange={this.handleURLChange}
        />
        &nbsp;&nbsp;
        Value &nbsp;
        <input
          type="text"
          value={this.state.Value}
          onChange={this.handleValueChange}
        />
        &nbsp;&nbsp;
        Label &nbsp;
        <input
          type="text"
          value={this.state.Label}
          onChange={this.handleLabelChange}
        />

      </div>
    </>);
  }
}
