import React from "react";

export default class ClassOutput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ComponentName: "",
      Navigates: false,
    };
  }


  render() {
    return (
      <div>
        <div
          id="divParentCode"
          style={{ background: "yellow", border: "1px solid", padding: "15px" }}
        >
          import&nbsp;
          {this.props.ComponentName}
          &nbsp;from&nbsp; "./{this.props.ComponentName}";
          <br /> {"<"}
          {this.props.ComponentName}
          {
            ' parentAttribute="Child Button" parentFunction={(childProp) => this.setState({ parentVar: childProp })} />'
          }
        </div>
        <br />
        <br />
        <br />
        <div
          id="divChildCode"
          style={{ background: "silver", border: "1px solid", padding: "15px" }}
        >
          import React from 'react';
          <br />
          {this.props.Navigates
            ? "import {withRouter} from 'react-router-dom';"
            : ""}
          {this.props.Navigates ? <br /> : ""}
          <br />
          {this.props.Navigates ? "" : "export default "}class{" "}
          {this.props.ComponentName} extends React.Component
          <br />
          {"{"}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;constructor(props)
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          {"{"}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;super(props);
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;this.state =
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"{"}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"}"}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          {"}"}
          <br />
          <br />

          &nbsp;&nbsp;&nbsp;&nbsp;render()
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;
          {"{"}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;return
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"<div>"}
          {this.props.Navigates ? <br /> : ""}
          {this.props.Navigates ? <br /> : ""}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {this.props.Navigates
            ? "<input type=\"button\" value={this.state.stringProp} onClick={() => this.props.history.push('/url')}/>"
            : ""}
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            '<input type="text" value={this.state.txtValue} onChange={(e) => {this.setState({ txtValue: e.target.value }); this.props.sendToParent(e.target.value)}}/>'
          }
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {
            "<input type=\"button\" value={this.props.parentAttribute} onClick={() => this.props.parentFunction('Send value to Parent')}/>"
          }
          <br />
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          {"</div>"}
          <br />
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; )
          <br /> &nbsp;&nbsp;&nbsp;&nbsp;
          {"}"}
          <br />
          <br />
          {"}"}
          <br />
          {this.props.Navigates
            ? "export default withRouter(" + this.props.ComponentName + ")"
            : ""}
        </div>
      </div>
    );
  }
}
